import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { jsx, Badge } from "theme-ui";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Badge sx={{
  mr: ".25em",
  mb: ".25em"
}}>{children}</Badge>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      