import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Badge } from "theme-ui";
import "./fonts.css";
import Layout from "../components/layout";
import Skill from "../components/skill";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <h1>{`Peter Sieg`}</h1>
      <h2>{`Senior Software Engineer`}</h2>
      <p><em parentName="p">{`Louisville, KY`}</em></p>
      <p>{`Self-motivated, results-oriented software engineer with a continual desire to
learn and 12+ years of experience focused on JavaScript,
TypeScript, Node.js, GraphQL, and AWS seeking full-time employment as a Senior+
Software Engineer.`}</p>
      <h2>{`Contact`}</h2>
      <ul>
        <li parentName="ul">{`Phone: `}<a parentName="li" {...{
            "href": "tel:502-552-6853"
          }}>{`502-552-6853`}</a></li>
        <li parentName="ul">{`Email: `}<a parentName="li" {...{
            "href": "mailto:chasingmaxwell@gmail.com"
          }}>{`chasingmaxwell@gmail.com`}</a></li>
      </ul>
      <h2>{`Profiles`}</h2>
      <ul>
        <li parentName="ul">{`NPM: `}<a parentName="li" {...{
            "href": "https://www.npmjs.com/~chasingmaxwell"
          }}>{`chasingmaxwell`}</a></li>
        <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
            "href": "https://github.com/chasingmaxwell"
          }}>{`chasingmaxwell`}</a></li>
      </ul>
      <h2>{`Work Experience`}</h2>
      <h3><a parentName="h3" {...{
          "href": "https://www.nbc.com/"
        }}>{`NBC Universal`}</a></h3>
      <p><strong parentName="p">{`Senior Software Engineer`}</strong></p>
      <p><em parentName="p">{`Remote (Louisville, KY) — 2020-present`}</em></p>
      <ul>
        <li parentName="ul">{`Led a team of engineers to develop and maintain the primary client-facing
GraphQL API for all NBC Universal applications and associated brands (SyFy,
Bravo, Oxygen, E, etc). Written in Node.js and deployed to an AWS ECS cluster,
it receives over 1.9 thousand requests per second while serving personalized,
presentational data with average response times between 300-500 milliseconds.`}</li>
        <li parentName="ul">{`Consolidated business logic from a number of platform applications into a
single presentational API, increasing efficiency and control over the
introduction of new features, simplifying experimentation with feature flags and
A/B tests, and reducing variance between platform implementations.`}</li>
        <li parentName="ul">{`Ensured the technical success of my team by defining technical direction,
championing best practices, teaching, overseeing work, and providing assistance.  `}</li>
        <li parentName="ul">{`Provided technical leadership and guidance across teams for the adoption of
GraphQL and the presentational API pattern.`}</li>
        <li parentName="ul">{`Defined technical architecture for new data pipelines/APIs and oversaw their
implementation.`}</li>
        <li parentName="ul">{`Continually adapted existing systems to accommodate new and evolving
requirements including internationalization, multi-brand experiences, data store
migrations, and the incorporation of NBC Sports.`}</li>
        <li parentName="ul">{`Identified performance bottlenecks across systems and defined strategies in
preparation for large traffic events, ensuring stability under extreme load.
During the Super Bowl LVI halftime show, for example, the primary client-facing
API saw 1.65 million requests per second without system failures or increase
to response time.`}</li>
        <li parentName="ul">{`Created and continue to maintain a number of open source Node.js packages
published to NPM and GitHub.`}</li>
      </ul>
      <h3><a parentName="h3" {...{
          "href": "https://www.fourkitchens.com/"
        }}>{`Four Kitchens`}</a></h3>
      <p><strong parentName="p">{`Senior JavaScript Engineer`}</strong></p>
      <p><em parentName="p">{`Remote (Louisville, KY) — 2014-2020`}</em></p>
      <ul>
        <li parentName="ul">{`Performed the roles of architect, technical lead, and software engineer on
projects for clients such as NBC Universal, Entertainment Weekly, Stanford Dean
of Research, University of California Davis, Twit.tv, Frontline Medical
Communications and others.`}</li>
        <li parentName="ul">{`Ensured project success by defining technical direction and overseeing
development with an eye toward quality, maintainability, and business
requirements.`}</li>
        <li parentName="ul">{`Built unique and complex websites with customized features and appearance to
meet client objectives.`}</li>
        <li parentName="ul">{`Collaborated with client stakeholders to discover and define project
requirements.`}</li>
        <li parentName="ul">{`Organized and led the Four Kitchens JavaScript Practice Group — an internal,
bi-weekly meetup focused on JavaScript education and professional development.`}</li>
      </ul>
      <h3><a parentName="h3" {...{
          "href": "https://prima-coffee.com/"
        }}>{`Prima LLC`}</a></h3>
      <p><strong parentName="p">{`Creative & Technical Director`}</strong></p>
      <p><em parentName="p">{`Louisville, KY — 2007-2014`}</em></p>
      <ul>
        <li parentName="ul">{`Led a team of engineers to implement new features, address bugs, and perform
regular maintenance on three separate e-commerce sites.`}</li>
        <li parentName="ul">{`Led efforts to address poor site performance, reducing server response time to
an average of 500ms.`}</li>
        <li parentName="ul">{`Developed custom Drupal modules and themes.`}</li>
        <li parentName="ul">{`Led the adoption of an agile development process, resulting in increased
efficiency.`}</li>
        <li parentName="ul">{`Created brand identities for prima-coffee.com and primasupply.com.`}</li>
      </ul>
      <h2>{`Other Experience`}</h2>
      <ul>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://github.com/chasingmaxwell/FeedBoost"
            }}>{`FeedBoost`}</a>{` — For fun and
professional development, I developed an application using Node.js, AWS Lambda
and the serverless framework to augment and enhance the notification system of
one of my favorite online marketplaces, `}<a parentName="p" {...{
              "href": "https://reverb.com"
            }}>{`Reverb.com`}</a>{`.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://github.com/chasingmaxwell/brain"
            }}>{`Brain`}</a>{` — This repository implements
an individual perceptron and visualizes its state as it learns from training
data. My goal was to better understand and demonstrate the inner workings of a
neural network by focusing on an individual neuron. This exercise greatly
increased my own understanding while providing knowledge sharing opportunities
with my co-workers and peers.`}</p>
        </li>
      </ul>
      <h2>{`Skills`}</h2>
      <Skill mdxType="Skill">JavaScript</Skill>
      <Skill mdxType="Skill">Node.js</Skill>
      <Skill mdxType="Skill">TypeScript</Skill>
      <Skill mdxType="Skill">API Design</Skill>
      <Skill mdxType="Skill">GraphQL</Skill>
      <Skill mdxType="Skill">AWS</Skill>
      <Skill mdxType="Skill">DynamoDB</Skill>
      <Skill mdxType="Skill">NoSQL</Skill>
      <Skill mdxType="Skill">REST</Skill>
      <Skill mdxType="Skill">Serverless</Skill>
      <Skill mdxType="Skill">Lambda</Skill>
      <Skill mdxType="Skill">Unit Testing</Skill>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      