import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, jsx } from "theme-ui";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Container p={4} sx={{
  maxWidth: ["100%", null, "700px"]
}}>
    {children}
  </Container>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      